import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4, SubTitle } from "components/core/Styled/Texts";
import { Input } from "components/core/Styled/Controls";
import Button from "components/reusable/Button";
import InputField from "components/reusable/Input";
import FileUpload, { TFile } from "components/Upload/UploadFiles";
import Loader from "components/core/Loaders/Loader";
import { errorString, haveErrors } from "utils/assertion";
import { useGoTo } from "common/hooks/paths/useGoTo";
import { routesList } from "utils/routesList";
import useFile from "common/hooks/helpers/useFile";
import { useFormik } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { HiOutlineCheck } from "react-icons/hi";
import styled, { useTheme } from "styled-components";
import { customToast } from "utils/customToast";
import { fqdnRegex, getMessageError } from "utils/helpers";
import { FiCopy } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { typeHostedKycToText } from "common/types/api/hostedKyc";
import { useContextNoCode } from "common/contexts/noCodeContext";
import { IGetConfigByIdResponse } from "api/hostedKycApi/getConfigById";
import { ConfigsLocalStorage } from "utils/Storage";
import { GoQuestion } from "react-icons/go";
import CardDisplay from "./CardDisplay";

type IConfigProps = {
  name: string;
  webhookUrl: string;
  webhookUserId: string;
  webhookPassword: string;
};

type IConfigErrorsProps = {
  name?: string;
  webhookUrl?: string;
  webhookUserId?: string;
  webhookPassword?: string;
};

const UpdateConfiguration = () => {
  const theme = useTheme();
  const goTo = useGoTo();
  const {
    configId,
    configsDisplay: configsData,
    saveConfigs,
  } = useContextNoCode();

  const [configsTemp, setConfigsTemp] = useState<
    Partial<IGetConfigByIdResponse> | undefined
  >();
  useEffect(() => {
    if (!configId) return;
    const configs = ConfigsLocalStorage.get();
    if (!configs || configs?.id !== configId) {
      ConfigsLocalStorage.clear();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...rest } = configs;
      setConfigsTemp(rest);
    }
  }, [configId]);

  const configsDisplay = useMemo(
    () => ({ ...configsData, ...configsTemp }),
    [configsData, configsTemp]
  );
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [logo, setLogo] = useState<TFile>();
  const [imageSrc, setImageSrc] = useState<string | undefined | null>();
  const [src] = useFile(logo);

  const validate = useCallback((values: IConfigProps) => {
    const errors: IConfigErrorsProps = {};
    errors.name = errorString({ name: values.name });
    return errors;
  }, []);

  const {
    values,
    setValues: setFormikValues,
    handleChange,
    handleBlur,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      webhookUrl: "",
      webhookUserId: "",
      webhookPassword: "",
      returnUrl: "",
      notificationEmail: "",
    },
    validate,
    onSubmit: () => console.info("Configuration submitted"),
  });

  const copyToClipBoard = useCallback((text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        customToast.success("Copied!");
      },
      () => {
        customToast.error("Copy failed!");
      }
    );
  }, []);

  const getChangeInput = useCallback(
    (save = false) => {
      const configs = {
        ...configsTemp,
        name: values.name,
        ...(values.webhookUrl && {
          webhook: {
            url: values.webhookUrl,
            ...(values.webhookUserId && { userId: values.webhookUserId }),
            ...(values.webhookPassword && {
              password: values.webhookPassword,
            }),
          },
        }),
        companyLogo: src ? src : imageSrc ? imageSrc : null,
        returnUrl: values.returnUrl,
        notificationEmail: values.notificationEmail,
      };
      if (save) ConfigsLocalStorage.set({ id: configId, ...configs });
      return configs;
    },
    [
      configId,
      configsTemp,
      imageSrc,
      src,
      values.name,
      values.notificationEmail,
      values.returnUrl,
      values.webhookPassword,
      values.webhookUrl,
      values.webhookUserId,
    ]
  );

  const onUpdateConfig = useCallback(async () => {
    if (haveErrors(errors)) {
      customToast.error("Please fill the required inputs");
      return;
    }
    const configs = getChangeInput();
    if (configs.returnUrl) {
      const validDomain = fqdnRegex.test(configs.returnUrl);
      if (!validDomain) {
        customToast.error("Please enter a valid FQDN with http:// or https://");
        return;
      }
    }
    try {
      await saveConfigs({ ...configs });
      customToast.success("No-code KYC updated successfully");
      goTo(routesList.noCodeIntegration.routes.main);
    } catch (error) {
      customToast.error(getMessageError(error, "No-code KYC update failed"));
      console.error(error);
    }
  }, [errors, getChangeInput, saveConfigs, goTo]);

  const onEditTexts = useCallback(() => {
    getChangeInput(true);
    goTo(routesList.noCodeIntegration.routes.editText, configId);
  }, [getChangeInput, goTo, configId]);

  const onConfigureWorkflow = useCallback(() => {
    getChangeInput(true);
    goTo(routesList.noCodeIntegration.routes.onboardingWorkflow, configId);
  }, [getChangeInput, goTo, configId]);

  const onboardingWorkflow = useMemo(
    () => configsDisplay?.onboardingWorkflow,
    [configsDisplay?.onboardingWorkflow]
  );

  useEffect(() => {
    if (configsDisplay) {
      setFormikValues({
        name: configsDisplay.name ?? "",
        returnUrl: configsDisplay.returnUrl ?? "",
        notificationEmail: configsDisplay.notificationEmail ?? "",
        webhookUrl: configsDisplay.webhook?.url ?? "",
        webhookUserId: configsDisplay.webhook?.userId ?? "",
        webhookPassword: configsDisplay.webhook?.password ?? "",
      });

      if (configsDisplay.companyLogo) {
        setImageSrc(configsDisplay.companyLogo);
      }
    }
  }, [setFormikValues, configsDisplay, setImageSrc]);

  useEffect(() => {
    const delay = 500;
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.select();
      }
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Wrapper>
      <Title>Configuration Data</Title>

      <InputField
        label="Type"
        name="type"
        value={typeHostedKycToText(
          configsDisplay?.type ?? "DESKTOP_MOBILE_BROWSER"
        )}
        readOnly
      />

      <InputField
        label="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.name}
        errors={errors.name}
        ref={inputRef}
      />

      <StyledRow>
        <StyledInputField
          label="URL"
          name="URL"
          value={configsDisplay?.url}
          readOnly
        />

        <CopyButton
          iconBefore={<FiCopy />}
          onClick={() => copyToClipBoard(configsDisplay?.url ?? "")}
        >
          <Heading3>Copy</Heading3>
        </CopyButton>
        <Link
          rel="noreferrer"
          target="_blank"
          href="https://docs.uqudo.com/docs/no-code-kyc/onboard-users"
        >
          <CopyButton iconBefore={<GoQuestion color={theme.color.main} />}>
            <Heading3>Help</Heading3>
          </CopyButton>
        </Link>
      </StyledRow>
      <Title>Workflow</Title>
      {configsDisplay !== undefined && (
        <CardDisplay onboardingWorkflow={onboardingWorkflow} />
      )}

      {!!onboardingWorkflow?.scanConfig?.optional ||
      !!onboardingWorkflow?.scanConfig?.required?.documentsConfigs ? (
        <WorkflowButton onClick={onConfigureWorkflow}>
          <Heading3>Configure Workflow</Heading3>
        </WorkflowButton>
      ) : (
        <WorkflowButton iconBefore={<IoMdAdd />} onClick={onConfigureWorkflow}>
          <Heading3>Add Workflow</Heading3>
        </WorkflowButton>
      )}
      <StyledDivider />
      <Title>Customize User Screen</Title>
      <Subtitle>Upload Logo</Subtitle>
      <FileUpload
        enable={true}
        file={logo}
        imgSrc={imageSrc}
        setImageSrc={setImageSrc}
        setFile={setLogo}
        buttonText="Select File"
      />
      <EditTextsButton onClick={onEditTexts}>
        <Heading3>Edit user instructions</Heading3>
      </EditTextsButton>

      <Title>Webhook</Title>
      <InputField
        label="Webhook URL"
        name="webhookUrl"
        value={values.webhookUrl}
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.webhookUrl}
        errors={errors.webhookUrl}
      />
      <InputField
        label="Webhook UserId"
        name="webhookUserId"
        value={values.webhookUserId}
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.webhookUserId}
        errors={errors.webhookUserId}
      />
      <InputField
        label="Webhook Password"
        name="webhookPassword"
        value={values.webhookPassword}
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.webhookPassword}
        errors={errors.webhookPassword}
      />
      <Title last>Others</Title>
      <InputField
        label="Return URL"
        name="returnUrl"
        value={values.returnUrl}
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.returnUrl}
        errors={errors.returnUrl}
      />
      <InputField
        label="Notification Email"
        name="notificationEmail"
        value={values.notificationEmail}
        onChange={handleChange}
        handleBlur={handleBlur}
        touched={touched.notificationEmail}
        errors={errors.notificationEmail}
      />
      {configsDisplay !== undefined ? (
        <ButtonStyled onClick={onUpdateConfig} iconBefore={<HiOutlineCheck />}>
          <Heading3>Save</Heading3>
        </ButtonStyled>
      ) : (
        <ButtonStyled>
          <Loader />
        </ButtonStyled>
      )}
    </Wrapper>
  );
};

export default UpdateConfiguration;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  min-height: 80vh;
  justify-content: flex-start;
`;

const StyledDivider = styled(Divider)`
  width: 26.25em;
  margin: 1em 0;
`;

const Link = styled.a`
  text-decoration: none;
`;

const ButtonStyled = styled(Button)<{ cancel?: boolean }>`
  border: ${(props) => (props.cancel ? "none" : props.theme.border.primary)};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: ${(props) => (props.cancel ? "8em" : "26.25em")};
  color: ${(props) =>
    props.cancel ? props.theme.color.black : props.theme.color.main};
  padding: 0 0.625em;
  margin-top: 3em;
  min-height: 2.1875em;
`;

const EditTextsButton = styled(Button)<{ cancel?: boolean }>`
  border: ${(props) => (props.cancel ? "none" : props.theme.border.primary)};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: ${(props) => (props.cancel ? "8em" : "26.25em")};
  color: ${(props) =>
    props.cancel ? props.theme.color.black : props.theme.color.main};
  padding: 0 0.625em;
  margin: 1em 0;
  min-height: 2.1875em;
`;

const Title = styled(SubTitle)<{ last?: boolean }>`
  color: ${(props) => props.theme.color.primary};
  font-weight: 500;
  padding: ${(props) => (props.last ? "1.5em 0 0.6em 0" : " 0 0 1em 0")}; ;
`;
const Subtitle = styled(Heading3)`
  color: ${(props) => props.theme.color.secondary2};
  font-weight: 500;
`;

const StyledRow = styled(Row)<{ popup?: boolean }>`
  width: 26.25em;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 1em 0;
`;

const StyledInputField = styled(InputField)`
  border-bottom: ${(props) => props.theme.border.main};

  ${Heading4} {
    padding: 0.625em 0;
    line-height: 0;
  }
  ${Container} {
    gap: 0;
    ${Input} {
      color: ${(props) => props.theme.color.main};
      font-size: 0.875em;
    }
  }
`;

const CopyButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  min-width: 5.75em;
  min-height: 2.1875em;
  margin: 0 0 0 0.725em;
`;

const WorkflowButton = styled(Button)`
  border: ${(props) => props.theme.border.primary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 9.1875em;
  min-height: 2.1875em;
  margin: 0 0 0.725em 0;
`;
