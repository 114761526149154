import { Container } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import Checkbox from "components/reusable/Checkbox";
import React, { useMemo } from "react";
import styled from "styled-components";
import { useContextWorkflow } from "common/contexts/workflowContext";
import _ from "lodash";
import { EmptyType } from "common/types/common";
import { CONFIG_DEFAULT_VALUES } from "common/types/api/hostedKyc";
import { useContextNoCode } from "common/contexts/noCodeContext";

type IProps = {};

const checkEmptyAndReturnDefault = (
  val: EmptyType<boolean>,
  def: boolean
): boolean => {
  if (_.isBoolean(val)) return val;
  return def;
};

const AdditionalScanConfigs = ({}: IProps) => {
  const { sdkCustomerConfig } = useContextNoCode();

  const {
    scanConfigAdditionalScanConfig: configs,
    onChangeScanAdditionalConfigs,
  } = useContextWorkflow();

  const configsAdditional = useMemo(
    () => ({
      enforceDocumentExpiryCheck: checkEmptyAndReturnDefault(
        configs?.enforceDocumentExpiryCheck,
        CONFIG_DEFAULT_VALUES.enforceDocumentExpiryCheck
      ),
      allowNonPhysicalDocuments: checkEmptyAndReturnDefault(
        configs?.allowNonPhysicalDocuments,
        CONFIG_DEFAULT_VALUES.allowNonPhysicalDocuments
      ),
      disableTamperingRejection: checkEmptyAndReturnDefault(
        configs?.disableTamperingRejection,
        CONFIG_DEFAULT_VALUES.disableTamperingRejection
      ),
    }),
    [
      configs?.allowNonPhysicalDocuments,
      configs?.disableTamperingRejection,
      configs?.enforceDocumentExpiryCheck,
    ]
  );

  if (
    !sdkCustomerConfig?.screenScanningAllowed &&
    !sdkCustomerConfig?.enforceDocumentExpiryCheckAllowed &&
    !sdkCustomerConfig?.disableTamperingRejectionAllowed
  )
    return <></>;

  return (
    <StyledConfiguration>
      <StyledName>Configurations</StyledName>
      {sdkCustomerConfig?.enforceDocumentExpiryCheckAllowed && (
        <StyledCheckbox
          label="Enforce Document Expiry Check"
          checked={configsAdditional.enforceDocumentExpiryCheck}
          onChange={() =>
            onChangeScanAdditionalConfigs({
              enforceDocumentExpiryCheck:
                !configsAdditional.enforceDocumentExpiryCheck,
            })
          }
        />
      )}
      {sdkCustomerConfig?.screenScanningAllowed && (
        <StyledCheckbox
          label="Allow screen scanning"
          checked={configsAdditional.allowNonPhysicalDocuments}
          onChange={() =>
            onChangeScanAdditionalConfigs({
              allowNonPhysicalDocuments:
                !configsAdditional.allowNonPhysicalDocuments,
            })
          }
        />
      )}
      {sdkCustomerConfig?.disableTamperingRejectionAllowed && (
        <StyledCheckbox
          label="Disable Tampering Rejection"
          checked={configsAdditional.disableTamperingRejection}
          onChange={() =>
            onChangeScanAdditionalConfigs({
              disableTamperingRejection:
                !configsAdditional.disableTamperingRejection,
            })
          }
        />
      )}
    </StyledConfiguration>
  );
};

export default AdditionalScanConfigs;

const StyledCheckbox = styled(Checkbox)`
  margin: 0.5em 0;
`;

const StyledConfiguration = styled(Container)`
  width: 100%;
  align-items: flex-start;
  padding: 0 1.5em;
  padding-bottom: 0.6em;
`;

const StyledName = styled(Heading3)`
  color: ${(props) => props.theme.color.black};
  padding: 0 0 0.6em 0;
`;
