import Status, { StyledIconContainer } from "components/Status/Status";
import {
  IVerificationInfo,
  isIdPhotoTamperingDetection,
  isIdPrintDetection,
  isIdScreenDetection,
  isSourceDetection,
} from "common/types/api/document";
import { Container, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import { fromCamelCaseToString } from "utils/helpers";
import { useCallback, useState } from "react";
import styled, { useTheme } from "styled-components";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

type IProps = {
  info: IVerificationInfo;
};
type IMrzData = {
  mrzData: IVerificationInfo["mrzChecksum"];
};
type IDataCheck = {
  dataCheck: IVerificationInfo["dataConsistencyCheck"];
};

const matchValues: { [key: string]: 0 | 1 | 2 } = {
  MATCH: 0,
  NO_MATCH: 2,
  MATCH_PARTIALLY: 1,
};

const matchTexts: { [key: string]: string } = {
  MATCH: "match",
  NO_MATCH: "no match",
  MATCH_PARTIALLY: "partially match",
};

const keyTexts: { [key: string]: string } = {
  sourceDetection: "Source Detection",
  idPhotoTamperingDetection: "Genuine ID Photo",
  idScreenDetection: "No Screen Capture",
  idPrintDetection: "No Printed Copy",
  lookup: "Lookup",
  dataConsistencyCheck: "Data Consistency Check",
  mrzChecksum: "MRZ Checksum",
  countrySigningCertificateAvailable: "Country Signing Certificate",
  documentSigningCertificateAvailable: "Document Signing Certificate",
  documentSigningCertificateSignatureValid: "Certification Path",
  documentDataSignatureValid: "Signature of Chip Data",
  activeAuthentication: "Active Authentication",
  chipAuthentication: "Chip Authentication",
};

const mapOrder = [
  "sourceDetection",
  "idPhotoTamperingDetection",
  "idScreenDetection",
  "idPrintDetection",
  "lookup",
  "dataConsistencyCheck",
  "mrzChecksum",
];

const MrzChecksum = ({ mrzData }: IMrzData) => {
  const [toggledMrz, setToggledMrz] = useState<boolean>(false);
  const theme = useTheme();
  const toggleShowMrz = useCallback(() => {
    setToggledMrz(!toggledMrz);
  }, [toggledMrz]);

  return (
    <Section>
      <Title>{keyTexts["mrzChecksum"]}</Title>
      <ToggleRowValue onClick={toggleShowMrz}>
        <StyledSubTitle>Overall Check</StyledSubTitle>
        <Row>
          <SubTitleValue isMatch={mrzData?.valid ? 0 : 2}>
            {mrzData?.valid ? "valid" : "not valid"}
          </SubTitleValue>
          <StyledStatus
            isMatch={mrzData?.valid ? 0 : 2}
            isSuccess={mrzData?.valid ?? false}
            isWarning={!mrzData?.valid}
            warningType={2}
            showText={false}
            showIcon
          />
          {toggledMrz ? (
            <IoIosArrowUp color={theme.color.main} />
          ) : (
            <IoIosArrowDown color={theme.color.main} />
          )}
        </Row>
      </ToggleRowValue>
      {toggledMrz && (
        <ToggleRowBloc>
          {mrzData?.checkDigits?.map((field, index) => {
            return (
              <ToggleValue key={index}>
                <ToggleKey>
                  {fromCamelCaseToString(field.fieldName as string)}
                </ToggleKey>
                <ToggleText>{field.fieldValue}</ToggleText>
                <StyledStatus
                  isMatch={field.valid ? 0 : 2}
                  isSuccess={field.valid ?? false}
                  isWarning={!field.valid}
                  warningType={2}
                  showText={false}
                  showIcon
                />
              </ToggleValue>
            );
          })}
        </ToggleRowBloc>
      )}
    </Section>
  );
};

const DataCheck = ({ dataCheck }: IDataCheck) => {
  const [toggledData, setToggledData] = useState<number[]>([]);
  const theme = useTheme();
  const toggleShowData = useCallback(
    (index: number) => {
      const indexExists = toggledData.includes(index);
      if (indexExists) {
        setToggledData(toggledData.filter((item) => item !== index));
      } else {
        setToggledData([...toggledData, index]);
      }
    },
    [toggledData]
  );

  return (
    <Section>
      <Title>{fromCamelCaseToString("dataConsistencyCheck")}</Title>
      {dataCheck?.fields?.map((field, index) => {
        return (
          <Container width="100%" key={index}>
            <ToggleRowValue onClick={() => toggleShowData(index)}>
              <ToggleTitle>
                {fromCamelCaseToString(field.name as string)}
              </ToggleTitle>
              <Row>
                <SubTitleValue isMatch={matchValues[field.match as string]}>
                  {matchTexts[field.match as string]}
                </SubTitleValue>
                <StyledStatus
                  isMatch={matchValues[field.match as string]}
                  isSuccess={field.match === "MATCH"}
                  isWarning={field.match !== "MATCH"}
                  warningType={field.match === "NO_MATCH" ? 2 : 1}
                  showText={false}
                  showIcon
                />
                {toggledData.includes(index) ? (
                  <IoIosArrowUp color={theme.color.main} />
                ) : (
                  <IoIosArrowDown color={theme.color.main} />
                )}
              </Row>
            </ToggleRowValue>
            {toggledData.includes(index) && (
              <ToggleRowBloc>
                {field.sources?.map((detail, index) => {
                  return (
                    <ToggleValue key={index}>
                      <ToggleKey>
                        {detail.source +
                          " " +
                          (detail.documentSide ?? "") +
                          " " +
                          fromCamelCaseToString(detail.name as string)}
                      </ToggleKey>
                      <ToggleText>{detail.value}</ToggleText>
                    </ToggleValue>
                  );
                })}
              </ToggleRowBloc>
            )}
          </Container>
        );
      })}
    </Section>
  );
};

export const Verification = ({ info }: IProps) => {
  return (
    <Wrapper>
      <DescriptionContainer>
        {Object.entries(info).some(
          ([key, value]) =>
            (isSourceDetection(key, value) ||
              isIdPhotoTamperingDetection(key, value) ||
              isIdScreenDetection(key, value) ||
              isIdPrintDetection(key, value)) &&
            value?.enabled
        ) && (
          <Section>
            <Title>Optical Fraud Detection</Title>
            {Object.entries(info)
              .filter(([key]) => key !== "documentType")
              .sort(([keyA], [keyB]) => {
                const indexA = mapOrder.indexOf(keyA);
                const indexB = mapOrder.indexOf(keyB);
                return indexA - indexB;
              })
              .map(([key, value], index) => {
                if (
                  (isIdPhotoTamperingDetection(key, value) ||
                    isIdScreenDetection(key, value) ||
                    isIdPrintDetection(key, value)) &&
                  value?.enabled
                ) {
                  return (
                    <RowValue key={index}>
                      {value?.enabled && (
                        <StyledSubTitle>{keyTexts[key]}</StyledSubTitle>
                      )}
                      <Row>
                        <SubTitleValue
                          isMatch={(value?.score ?? 0) < 50 ? 2 : 0}
                        >
                          {value?.score}
                        </SubTitleValue>
                        <StyledStatus
                          isMatch={(value?.score ?? 0) < 50 ? 2 : 0}
                          isSuccess={(value?.score ?? 0) >= 50}
                          isWarning={(value?.score ?? 0) < 50}
                          warningType={2}
                          showText={false}
                          showIcon
                        />
                      </Row>
                    </RowValue>
                  );
                } else if (isSourceDetection(key, value) && value?.enabled) {
                  return (
                    <>
                      {info["sourceDetection"]?.allowNonPhysicalDocuments && (
                        <RowValue>
                          <StyledSubTitle>
                            {fromCamelCaseToString("allowNonPhysicalDocuments")}
                          </StyledSubTitle>

                          <Row>
                            <SubTitleValue isMatch={0}>true</SubTitleValue>
                            <Status isSuccess showText={false} showIcon />
                          </Row>
                        </RowValue>
                      )}
                      {info["sourceDetection"]?.optimalResolution && (
                        <RowValue>
                          <StyledSubTitle>
                            {fromCamelCaseToString("optimalResolution")}
                          </StyledSubTitle>

                          <Row>
                            <SubTitleValue isMatch={0}>Yes</SubTitleValue>
                            <SubTitleValue isMatch={0}>
                              {"("}
                              {info["sourceDetection"]?.selectedResolution}
                              {")"}
                            </SubTitleValue>
                            <Status isSuccess showText={false} showIcon />
                          </Row>
                        </RowValue>
                      )}
                    </>
                  );
                }
              })}
          </Section>
        )}
        {info.reading?.enabled &&
          (info.reading?.passiveAuthentication?.enabled ||
            info.reading?.chipAuthentication?.enabled) && (
            <Section>
              <Title>NFC Chip Fraud Detection</Title>
              {info.reading?.passiveAuthentication?.enabled &&
                Object.entries(info.reading?.passiveAuthentication)
                  .filter(([key]) => key !== "enabled")
                  .map(([key, value], index) => {
                    return (
                      <>
                        {((key !== "countrySigningCertificateAvailable" &&
                          key !== "documentDataSignatureValid" &&
                          value) ||
                          key === "countrySigningCertificateAvailable" ||
                          key === "documentDataSignatureValid") && (
                          <RowValue key={index}>
                            <StyledSubTitle>{keyTexts[key]}</StyledSubTitle>
                            <Row>
                              {key === "countrySigningCertificateAvailable" && (
                                <CustomValue isMatch={value ? 0 : 1}>
                                  {value ? "Yes" : "Not Available"}
                                </CustomValue>
                              )}
                              {key === "documentDataSignatureValid" && (
                                <CustomValue isMatch={value ? 0 : 2}>
                                  {value ? "Valid" : "Failed"}
                                </CustomValue>
                              )}

                              {key !== "countrySigningCertificateAvailable" &&
                                key !== "documentDataSignatureValid" &&
                                value && (
                                  <CustomValue isMatch={0}>
                                    {key ===
                                    "documentSigningCertificateAvailable"
                                      ? "Yes"
                                      : "Valid"}
                                  </CustomValue>
                                )}
                            </Row>
                          </RowValue>
                        )}
                      </>
                    );
                  })}
              {info.reading?.chipAuthentication?.enabled && (
                <RowValue>
                  <StyledSubTitle>
                    {keyTexts["chipAuthentication"]}
                  </StyledSubTitle>

                  <Row>
                    <CustomValue isMatch={0}>Valid</CustomValue>
                  </Row>
                </RowValue>
              )}
            </Section>
          )}
        {info.lookup?.enabled && (
          <Section>
            <Title>Lookup</Title>
            <RowValue>
              <StyledSubTitle>
                {fromCamelCaseToString("documentFound")}
              </StyledSubTitle>

              <Row>
                <SubTitleValue isMatch={info["lookup"].documentFound ? 0 : 2}>
                  {info["lookup"].documentFound ? "true" : "false"}
                </SubTitleValue>
                <StyledStatus
                  isMatch={info["lookup"].documentFound ? 0 : 2}
                  isSuccess={info["lookup"].documentFound ?? false}
                  isWarning={!info["lookup"].documentFound}
                  warningType={info["lookup"].documentFound ? 1 : 2}
                  showText={false}
                  showIcon
                />
              </Row>
            </RowValue>
          </Section>
        )}
        {info.dataConsistencyCheck?.enabled &&
        info["dataConsistencyCheck"]?.fields?.length &&
        info["dataConsistencyCheck"]?.fields?.length > 0 ? (
          <DataCheck dataCheck={info["dataConsistencyCheck"]} />
        ) : (
          <></>
        )}
        {info.mrzChecksum?.enabled && (
          <MrzChecksum mrzData={info.mrzChecksum} />
        )}
      </DescriptionContainer>
    </Wrapper>
  );
};

export default Verification;

const Wrapper = styled(Row)`
  width: 100%;
  max-width: 30em;
  justify-content: flex-start;
  align-items: flex-start;
`;

const DescriptionContainer = styled(Container)`
  width: 100%;
  justify-content: flex-start;
`;

const Section = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.5em;
  padding: 0.675em 0 0 0;
  border-top: ${(props) => props.theme.border.main};
`;

const RowValue = styled(Row)`
  width: 100%;
  justify-content: space-between;
  flex: 2;
  padding: 0 0;
`;
const ToggleRowValue = styled(Row)`
  width: 100%;
  justify-content: space-between;
  border: ${(props) => props.theme.border.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  flex: 2;
  padding: 0.25em 0.5em;
  margin: 0.5em 0;
  cursor: pointer;
`;
const ToggleValue = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: ${(props) => props.theme.borderRadius.primary};
  flex: 2;
  padding: 0 0.5em;
`;

const ToggleKey = styled(Heading4)`
  width: 100%;
  flex: 1;
  font-weight: 400;
  text-align: left;
  padding: 0.5em 0;
  line-height: 1.15em;
  color: ${(props) => props.theme.color.secondary2};
`;
const ToggleText = styled(Heading4)`
  width: 100%;
  flex: 1;
  text-align: right;
  padding: 0.25em 0;
  line-height: 1.15em;

  color: ${(props) => props.theme.color.black};
`;
const ToggleRowBloc = styled(Container)`
  width: 100%;
  justify-content: space-between;
  border: ${(props) => props.theme.border.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  background-color: ${(props) => props.theme.background.secondary};
  flex: 2;
  padding: 0.875em 0.5em;
  margin: 0.5em 0;
`;

const Title = styled(Heading3)`
  width: 100%;
  font-weight: 500;
  text-align: left;
  color: ${(props) => props.theme.color.main};
  padding: 0.675em 0;
  font-size: 0.95rem;
`;

const StyledSubTitle = styled(Heading4)`
  width: 100%;
  flex: 1;
  text-align: left;
  padding: 0.25em 0;
`;
const ToggleTitle = styled(Heading3)`
  width: 100%;
  flex: 1;
  text-align: left;
  padding: 0.25em 0;
  font-weight: 500;
`;
const SubTitleValue = styled(Heading4)<{ isMatch?: 0 | 1 | 2 }>`
  width: 100%;
  flex: 1;
  text-align: right;
  padding: 0.25em 0 0.25em 0.25em;
  color: ${(props) =>
    props.isMatch === 0
      ? props.theme.color.success
      : props.isMatch === 2
      ? props.theme.color.danger
      : props.theme.color.warning};
`;
const CustomValue = styled(Heading4)<{ isMatch?: 0 | 1 | 2 }>`
  width: 100%;
  flex: 1;
  text-align: right;
  padding: 0.25em 0.625em 0.25em 0.25em;
  color: ${(props) =>
    props.isMatch === 0
      ? props.theme.color.success
      : props.isMatch === 2
      ? props.theme.color.danger
      : props.theme.color.warning};
`;

const StyledStatus = styled(Status)<{ isMatch?: 0 | 1 | 2 }>`
  ${StyledIconContainer} {
    svg {
      path {
        stroke: ${(props) =>
          props.isMatch === 0
            ? props.theme.color.success
            : props.isMatch === 2
            ? props.theme.color.danger
            : props.theme.color.warning};
      }
    }
  }
`;
