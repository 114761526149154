import { fromCamelCaseToString } from "./helpers";
import { MIN_LENGTH_SEARCH_PATTERN, MIN_LENGTH_STRING } from "./constants";
import { IDate, TInfo } from "common/types/api/common";
import moment from "moment";
import { EmptyType } from "common/types/common";

export const cleanUpStr = (str?: EmptyType<string>) => {
  if (!str) return str;
  let strTrim = str.trim();
  strTrim = strTrim.replace(/\s\s+/g, " ");

  return strTrim;
};

export const isValidSearch = (
  search: EmptyType<string>,
  required = false,
  minLength = MIN_LENGTH_SEARCH_PATTERN
) => {
  const cleanupSearch = search;
  if (
    (required && !cleanupSearch) ||
    (cleanupSearch &&
      cleanupSearch.length > 0 &&
      cleanupSearch.length < minLength)
  )
    return false;
  return true;
};

export const assertSearch = (
  search: EmptyType<string>,
  required = false,
  minLength = MIN_LENGTH_SEARCH_PATTERN
) => {
  if (!isValidSearch(search, required, minLength))
    throw new Error(`search length must be greater than ${minLength}`);
};

export const errorString = (obj: Record<string, string | undefined>) => {
  for (const [key, str] of Object.entries(obj)) {
    if (!str) return `${fromCamelCaseToString(key)} is required`;
    if (!isValidString(str, { min: MIN_LENGTH_STRING }))
      return `${fromCamelCaseToString(
        key
      )} must not be less than ${MIN_LENGTH_STRING} characters`;
  }
};

export const assertNotEmpty = (...args: TInfo[]) => {
  args.forEach((val) => {
    if (!val) throw new Error("field must be not empty");
  });
};

export const isObjEmpty = (obj: Record<string | number | symbol, unknown>) => {
  return Object.values(obj).every((val) => !val);
};

export const istNotEmptyObj = (
  obj: Record<string | number | symbol, unknown>
) => {
  return Object.values(obj).some((val) => !val);
};

export const isEmpty = (obj: Record<string | number | symbol, unknown>) => {
  return !(Object.keys(obj).length > 0);
};

export const validateEmail = (email: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const isValidString = (
  str: string,
  { min = 1, max }: { min?: number; max?: number }
) => {
  const data = cleanUpStr(str.toString());
  if (!data) return false;
  const len = data.length;
  if (len < min) return false;
  if (max && len > max) false;
  return true;
};

export const assertFutureDate = (date: IDate) => {
  if (moment(date).isSameOrAfter(moment.now(), "day")) return;
  throw new Error("the date must be in the future");
};

export const errorFutureDate = (date: IDate) => {
  if (moment(date).isSameOrAfter(moment.now(), "day")) return "";
  return "the date must be in the future";
};

export const haveErrors = (errors: Record<string, string | undefined>) => {
  const hasErrors = Object.values(errors).some((value) => !!value);
  if (hasErrors) return true;
  return false;
};

export const haveTouched = (touched: Record<string, boolean | undefined>) => {
  const hasTouched = Object.values(touched).some((value) => !!value);
  if (hasTouched) return true;
  return false;
};

export const canBeSaved = (
  errors: Record<string, string | undefined>,
  touched: Record<string, boolean | undefined>
) => {
  if (!haveTouched(touched)) return true;
  return !haveErrors(errors);
};

function isStrongPassword(password: string): boolean {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  return (
    password.length >= minLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar
  );
}

export function errorPassword(
  password: string | undefined
): string | undefined {
  if (!password || !isStrongPassword(password)) {
    return "field must contain at least 8 characters (Lower case letters (a-z), Upper case letters (A-Z), Numbers (0-9), Special characters)";
  }
  return undefined;
}

export const errorNotEmpty = (str: string | undefined) => {
  if (!str) return "field must be not empty";
  return undefined;
};
