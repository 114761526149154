import { icons } from "assets/images";
import { useContextNoCode } from "common/contexts/noCodeContext";
import { useContextWorkflow } from "common/contexts/workflowContext";
import { CONFIG_DEFAULT_VALUES } from "common/types/api/hostedKyc";
import { Container, Divider } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import Checkbox from "components/reusable/Checkbox";
import NodeCard from "components/Workflow/NodeCard";
import React from "react";
import styled from "styled-components";

type IProps = {};

const BackgroundCheckNode = ({}: IProps) => {
  const { sdkCustomerConfig } = useContextNoCode();

  const {
    onChangeBackgroundCheckConfig: onChange,
    backgroundCheckConfig: conf,
  } = useContextWorkflow();

  return (
    <Wrapper>
      <NodeCard
        title="Background Screening"
        subTitle="Screens the background of the user."
        icon={icons.bgCheck}
      />
      <Divider />
      <ConfigurationStyled>
        <Name>Configurations</Name>
        {/* not always allowed to change Monitoring I removed all checkbox. */}
        {sdkCustomerConfig?.bcMonitoringAllowed && (
          <Checkbox
            onChange={() =>
              onChange({
                enableMonitoring: !(
                  conf?.enableMonitoring ??
                  CONFIG_DEFAULT_VALUES.enableMonitoring
                ),
              })
            }
            checked={
              conf?.enableMonitoring ?? CONFIG_DEFAULT_VALUES.enableMonitoring
            }
            label="Monitoring"
          />
        )}
        <Checkbox
          onChange={() =>
            onChange({
              disableConsent: !(
                conf?.disableConsent ?? CONFIG_DEFAULT_VALUES.disableConsent
              ),
            })
          }
          checked={conf?.disableConsent ?? CONFIG_DEFAULT_VALUES.disableConsent}
          label="Disable Consent"
        />
        <Checkbox
          onChange={() =>
            onChange({
              skipView: !(conf?.skipView ?? CONFIG_DEFAULT_VALUES.skipView),
            })
          }
          checked={conf?.skipView ?? CONFIG_DEFAULT_VALUES.skipView}
          label="Skip View"
        />
      </ConfigurationStyled>
    </Wrapper>
  );
};

export default BackgroundCheckNode;

const Wrapper = styled(Container)`
  width: 100%;
`;

const ConfigurationStyled = styled(Container)`
  width: 100%;
  align-items: flex-start;
  padding: 0 1.5em;
`;

const Name = styled(Heading3)`
  color: ${(props) => props.theme.color.black};
  padding: 1em 0 0 0;
`;

export const AuthDivider = styled.div`
  width: 26.25em;
  height: 0;
  border-top: ${(props) => props.theme.border.main};
  margin-top: 1em;
  padding: 0.5em 0;
`;
