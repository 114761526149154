import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3 } from "components/core/Styled/Texts";
import Checkbox from "components/reusable/Checkbox";
import CustomSelect from "components/Select/CustomSelect";
import Button from "components/reusable/Button";
import React, { Fragment, useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import { RiDeleteBin5Line } from "react-icons/ri";
import ToggleBloc from "components/Toggle/ToggleBloc";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  documentIdTo,
  getDocumentId,
  ICountryMap,
  IDocumentMap,
  ISetCountryMap,
  ISetDocumentMap,
} from "common/types/api/document";
import { useContextNoCode } from "common/contexts/noCodeContext";

type IProps = {
  isSelectedDocument: IDocumentMap;
  setIsSelectedDocument: ISetDocumentMap;
  isSelectedCountry: ICountryMap;
  setIsSelectedCountry: ISetCountryMap;
};

const MultiDocumentSelect = ({
  isSelectedDocument,
  setIsSelectedDocument,
  isSelectedCountry,
  setIsSelectedCountry,
}: IProps) => {
  const theme = useTheme();

  const { sdkCustomerConfig, documentListById, countryListById } =
    useContextNoCode();

  const toggleCountry = useCallback(
    (country?: string) => {
      if (country === undefined) return;
      setIsSelectedCountry((oldValues) => {
        const newValues = new Map(oldValues);
        if (newValues.has(country)) newValues.delete(country);
        else newValues.set(country, { open: true });
        return newValues;
      });
    },
    [setIsSelectedCountry]
  );

  const toggleCountryOpen = useCallback(
    (country?: string) => {
      if (country === undefined) return;
      setIsSelectedCountry((oldValues) => {
        const val = oldValues.get(country);
        if (!val) return oldValues;
        const newValues = new Map(oldValues);
        newValues.set(country, { ...val, open: !val.open });
        return newValues;
      });
    },
    [setIsSelectedCountry]
  );

  const toggleDocument = useCallback(
    (documentId?: string) => {
      if (documentId === undefined) return;
      setIsSelectedDocument((oldValues) => {
        const newValues = new Map(oldValues);
        if (newValues.has(documentId)) newValues.delete(documentId);
        else {
          const { documentType, country } = documentIdTo(documentId);
          newValues.set(documentId, {
            lookup: false,
            nfc: false,
            country,
            documentType,
          });
        }
        return newValues;
      });
    },
    [setIsSelectedDocument]
  );

  const toggleDocumentLookup = useCallback(
    (documentId?: string) => {
      if (documentId === undefined) return;
      setIsSelectedDocument((oldValues) => {
        const val = oldValues.get(documentId);
        if (!val) return oldValues;
        const newValues = new Map(oldValues);
        newValues.set(documentId, { ...val, lookup: !val.lookup });
        return newValues;
      });
    },
    [setIsSelectedDocument]
  );

  const toggleDocumentNfc = useCallback(
    (documentId?: string) => {
      if (documentId === undefined) return;
      setIsSelectedDocument((oldValues) => {
        const val = oldValues.get(documentId);
        if (!val) return oldValues;
        const newValues = new Map(oldValues);
        newValues.set(documentId, { ...val, nfc: !val.nfc });
        return newValues;
      });
    },
    [setIsSelectedDocument]
  );

  const passportDoc = useMemo(() => {
    if (!documentListById) return;
    const documentId = getDocumentId("PASSPORT", "");
    const docInfo = documentListById?.[documentId];
    if (!docInfo) return;
    const docSelected = isSelectedDocument.get(documentId);

    return {
      documentId,
      ...docInfo,
      selected: !!docSelected,
      ...docSelected,
    };
  }, [documentListById, isSelectedDocument]);

  const countryList = useMemo(
    () =>
      Object.keys(countryListById ?? {}).map((country) => ({
        label: country,
        value: country,
      })) ?? [],
    [countryListById]
  );

  return (
    <>
      {countryList.length > 0 && (
        <ConfigurationStyled>
          <Name>ID Document</Name>
          <StyledCustomSelect
            options={countryList.filter((x) => !isSelectedCountry.has(x.value))}
            onChange={(newValue) => toggleCountry(newValue.value as string)}
            selectStyle="100%"
            hideSelectedOptions={true}
            placeholder="Select Country..."
            forceClear={true}
          />
          {Array.from(isSelectedCountry.keys()).map((country) => {
            if (country !== "") {
              const countrySelected = isSelectedCountry.get(country);
              return (
                <CountryRow key={country}>
                  <Row width="100%" justifyContent="space-between">
                    <CountryTitle onClick={() => toggleCountryOpen(country)}>
                      <Heading3>{country}</Heading3>
                    </CountryTitle>
                    <Button
                      iconAfter={<RiDeleteBin5Line color={theme.color.main} />}
                      onClick={() => toggleCountry(country)}
                    />
                    {countrySelected?.open ? (
                      <Button
                        iconAfter={<IoIosArrowUp color={theme.color.main} />}
                        onClick={() => toggleCountryOpen(country)}
                      />
                    ) : (
                      <Button
                        iconAfter={<IoIosArrowDown color={theme.color.main} />}
                        onClick={() => toggleCountryOpen(country)}
                      />
                    )}
                  </Row>

                  {countrySelected?.open && <CountryDivider />}

                  {countrySelected?.open &&
                    countryListById[country].documents.map((docType) => {
                      const documentId = getDocumentId(docType, country);
                      const docInfo = documentListById[documentId];
                      const docSelected = isSelectedDocument.get(documentId);
                      return (
                        <Fragment key={documentId}>
                          <DocumentTypeRow>
                            <StyledCheckbox
                              onChange={() => toggleDocument(documentId)}
                              checked={!!docSelected}
                              label={docInfo.documentName}
                            />
                          </DocumentTypeRow>
                          {!!docSelected &&
                            docInfo.canBeLookup &&
                            sdkCustomerConfig?.lookupAllowed?.some(
                              (documentType) =>
                                documentType ===
                                "KYC_LOOKUP_SDK_" + docInfo.documentType
                            ) && (
                              <StyledToggleBloc
                                label="Lookup"
                                onChange={() =>
                                  toggleDocumentLookup(documentId)
                                }
                                toggle={!!docSelected?.lookup}
                              />
                            )}
                          {!!docSelected &&
                            docInfo.canBeNfc &&
                            sdkCustomerConfig?.nfcAllowed && (
                              <StyledToggleBloc
                                label="NFC"
                                onChange={() => toggleDocumentNfc(documentId)}
                                toggle={!!docSelected?.nfc}
                              />
                            )}
                        </Fragment>
                      );
                    })}
                </CountryRow>
              );
            }
          })}

          {passportDoc !== undefined && (
            <>
              <StyledDivider />
              <>
                <DocumentTypeRow>
                  <StyledCheckbox
                    onChange={() => toggleDocument(passportDoc?.documentId)}
                    checked={!!passportDoc?.selected}
                    label={"Passports from all countries"}
                  />
                </DocumentTypeRow>
                {passportDoc?.selected &&
                  passportDoc?.canBeLookup &&
                  sdkCustomerConfig?.lookupAllowed?.some(
                    (documentType) =>
                      documentType ===
                      "KYC_LOOKUP_SDK_" + passportDoc?.documentType
                  ) && (
                    <StyledToggleBloc
                      label="Lookup"
                      onChange={() =>
                        toggleDocumentLookup(passportDoc?.documentId)
                      }
                      toggle={!!passportDoc?.lookup}
                    />
                  )}
                {passportDoc?.selected &&
                  passportDoc?.canBeNfc &&
                  sdkCustomerConfig?.nfcAllowed && (
                    <StyledToggleBloc
                      label="NFC"
                      onChange={() =>
                        toggleDocumentNfc(passportDoc?.documentId)
                      }
                      toggle={!!passportDoc?.nfc}
                    />
                  )}
              </>
            </>
          )}
        </ConfigurationStyled>
      )}
    </>
  );
};

export default MultiDocumentSelect;

const ConfigurationStyled = styled(Container)`
  width: 100%;
  align-items: flex-start;
  padding: 0 1.5em;
`;

const Name = styled(Heading3)`
  color: ${(props) => props.theme.color.black};
  padding: 1em 0 0.6em 0;
`;
const StyledCheckbox = styled(Checkbox)`
  margin: 0.5em 0;
`;
const StyledDivider = styled(Divider)`
  margin: 1em 0;
`;
const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
  margin: 0 0 1em 0;
  ${Container} {
    border: none;
    border-radius: 0;
    border-bottom: ${(props) => props.theme.border.main};
  }
`;

const CountryRow = styled(Container)`
  width: 100%;
  min-height: 3em;
  height: auto;
  border: ${(props) => props.theme.border.main};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  padding: 0.6em 0.6em;
  margin: 0.3em 0;
`;
const CountryDivider = styled(Divider)`
  margin: 0.6em 0;
`;

const CountryTitle = styled(Row)`
  width: 80%;
  justify-content: flex-start;
`;

const DocumentTypeRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledToggleBloc = styled(ToggleBloc)`
  width: 100%;
  ${Row} {
    padding: 0 0.6em;
  }
  ${Heading3} {
    color: ${(props) => props.theme.color.black};
  }
  .react-toggle-track {
    width: 40px;
    height: 19px;
    border-radius: 18px;
  }
  .react-toggle-thumb {
    width: 15px;
    height: 15px;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 22px;
  }
`;
