import { icons } from "assets/images";
import { Container, Divider } from "components/core/Styled/Layouts";
import NodeCard from "components/Workflow/NodeCard";
import React from "react";
import styled from "styled-components";
import AdditionalScanConfigs from "./AdditionalScanConfigs";
import MultiDocumentSelect from "./MultiDocumentSelect";
import { useContextWorkflow } from "common/contexts/workflowContext";

type IProps = {};

const MandatoryIdNode = ({}: IProps) => {
  const {
    isSelectedDocumentRequired: isSelectedDocument,
    setIsSelectedDocumentRequired: setIsSelectedDocument,
    isSelectedCountryRequired: isSelectedCountry,
    setIsSelectedCountryRequired: setIsSelectedCountry,
  } = useContextWorkflow();

  return (
    <Wrapper>
      <NodeCard
        title="Mandatory ID Scanning"
        subTitle="User must onboard all selected IDs."
        icon={icons.reqId}
      />
      <AdditionalScanConfigs />
      <Divider />
      <MultiDocumentSelect
        isSelectedDocument={isSelectedDocument}
        setIsSelectedDocument={setIsSelectedDocument}
        isSelectedCountry={isSelectedCountry}
        setIsSelectedCountry={setIsSelectedCountry}
      />
    </Wrapper>
  );
};

export default MandatoryIdNode;

const Wrapper = styled(Container)`
  width: 100%;
  padding-bottom: 1em;
`;
