import { useCallback, useState } from "react";

export function usePartialState<T extends object>(
  initialState?: T | undefined | null
): [
  T | undefined | null,
  React.Dispatch<React.SetStateAction<T | null | undefined>>,
  (partialState: Partial<T>) => void
] {
  const [state, setState] = useState<T | undefined | null>(initialState);

  const setPartialState = useCallback((partialState: Partial<T>) => {
    setState((prevState) => {
      if (!prevState) return partialState as T | undefined | null;

      return {
        ...prevState,
        ...partialState,
      };
    });
  }, []);

  return [state, setState, setPartialState];
}
