import { useSearchParams } from "react-router-dom";
import { useAuthLogin } from "services/auth/token/useAuthLogin";
import { useCallback, useEffect, useMemo } from "react";
import LoadingPage from "components/core/Loaders/LoadingPage";

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const { handleLogin } = useAuthLogin();

  const code = useMemo(
    () => searchParams.get("code") ?? "auth_failed",
    [searchParams]
  );

  const login = useCallback(() => {
    const force = code === "logout" || code === "user_forbidden";
    handleLogin(force);
  }, [code, handleLogin]);

  useEffect(() => {
    login();
  }, [login]);

  return <LoadingPage />;
};

export default LoginPage;
