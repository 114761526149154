import { icons } from "assets/images";
import { Container, Divider } from "components/core/Styled/Layouts";
import NodeCard from "components/Workflow/NodeCard";
import React from "react";
import styled from "styled-components";
import AdditionalScanConfigs from "./AdditionalScanConfigs";
import MultiDocumentSelect from "./MultiDocumentSelect";
import { useContextWorkflow } from "common/contexts/workflowContext";

type IProps = {};

const OptionalIdNode = ({}: IProps) => {
  const {
    isSelectedDocumentOptional: isSelectedDocument,
    setIsSelectedDocumentOptional: setIsSelectedDocument,
    isSelectedCountryOptional: isSelectedCountry,
    setIsSelectedCountryOptional: setIsSelectedCountry,
  } = useContextWorkflow();

  return (
    <Wrapper>
      <NodeCard
        title="User's Choice ID Scanning"
        subTitle="User chooses one of the selected IDs."
        icon={icons.opId}
      />
      <AdditionalScanConfigs />
      <Divider />
      <MultiDocumentSelect
        isSelectedDocument={isSelectedDocument}
        setIsSelectedDocument={setIsSelectedDocument}
        isSelectedCountry={isSelectedCountry}
        setIsSelectedCountry={setIsSelectedCountry}
      />
    </Wrapper>
  );
};

export default OptionalIdNode;

const Wrapper = styled(Container)`
  width: 100%;
  padding-bottom: 1em;
`;
