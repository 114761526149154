import { useContextNoCode } from "common/contexts/noCodeContext";
import {
  CONFIG_DEFAULT_VALUES,
  IAdditionalScanConfig,
  IBackgroundCheckConfig,
  IDocumentConfigs,
  IFaceConfig,
  IOnboardingWorkflow,
} from "common/types/api/hostedKyc";
import { useCallback, useEffect, useMemo, useState } from "react";
import { icons } from "assets/images";
import { usePartialState } from "common/hooks/helpers/usePartialState";
import _ from "lodash";
import {
  getDocumentId,
  ICountryInfo,
  ICountryMap,
  IDocumentMap,
  IDocumentSelectedInfo,
  ISetCountryMap,
  ISetDocumentMap,
} from "common/types/api/document";

export type IStopsProps = {
  title: string;
  icon: string;
  active: boolean;
  key: "optional" | "required" | "backgroundCheckConfig" | "faceConfig";
};

export const workflowStatic: IStopsProps[] = [
  {
    key: "required",
    title: "Mandatory ID Scanning",
    icon: icons.opId,
    active: false,
  },
  {
    key: "optional",
    title: "User's Choice ID Scanning",
    icon: icons.reqId,
    active: false,
  },
  {
    key: "faceConfig",
    title: "Face Authentication",
    icon: icons.faceCheck,
    active: false,
  },
  {
    key: "backgroundCheckConfig",
    title: "Background Screening",
    icon: icons.bgCheck,
    active: false,
  },
];

const init = (
  documentsConfigs: IDocumentConfigs[] | undefined,
  setIsSelectedDocument: ISetDocumentMap,
  setIsSelectedCountry: ISetCountryMap
) => {
  const documentListMap = new Map<string, IDocumentSelectedInfo>();
  const countryListMap = new Map<string, ICountryInfo>();
  if (documentsConfigs) {
    documentsConfigs.forEach((doc) => {
      const documentId = getDocumentId(doc.documentType, doc.country);
      documentListMap.set(documentId, {
        country: doc.country,
        lookup: doc.lookup ?? false,
        nfc: doc.nfc ?? false,
        documentType: doc.documentType,
      });
      countryListMap.set(doc.country, { open: true });
    });
  }
  setIsSelectedCountry(countryListMap);
  setIsSelectedDocument(documentListMap);
};

function useWorkflowConfigs() {
  const {
    configsDisplay,
    saveConfigs,
    sdkCustomerConfig,
    fetchSdkCustomerConfig,
  } = useContextNoCode();

  const [isSelectedDocumentRequired, setIsSelectedDocumentRequired] =
    useState<IDocumentMap>(new Map<string, IDocumentSelectedInfo>());

  const [isSelectedCountryRequired, setIsSelectedCountryRequired] =
    useState<ICountryMap>(new Map<string, ICountryInfo>());

  const [isSelectedDocumentOptional, setIsSelectedDocumentOptional] =
    useState<IDocumentMap>(new Map<string, IDocumentSelectedInfo>());

  const [isSelectedCountryOptional, setIsSelectedCountryOptional] =
    useState<ICountryMap>(new Map<string, ICountryInfo>());

  const documentsConfigsRequiredList = useMemo(() => {
    const res: IDocumentConfigs[] = Array.from(
      isSelectedDocumentRequired.values()
    );
    return res;
  }, [isSelectedDocumentRequired]);

  const documentsConfigsOptionalList = useMemo(() => {
    const res: IDocumentConfigs[] = Array.from(
      isSelectedDocumentOptional.values()
    );
    return res;
  }, [isSelectedDocumentOptional]);

  const [
    scanConfigAdditionalScanConfig,
    setScanConfigAdditionalScanConfig,
    onChangeScanAdditionalConfigs,
  ] = usePartialState<IAdditionalScanConfig>();

  const [faceConfig, setFaceConfig, onChangeFaceConfig] =
    usePartialState<IFaceConfig>();

  const [
    backgroundCheckConfig,
    setBackgroundCheckConfig,
    onChangeBackgroundCheckConfig,
  ] = usePartialState<IBackgroundCheckConfig>();

  const [stops, setStops] = useState<IStopsProps[]>(workflowStatic);
  const [activeNode, setActiveNode] = useState<IStopsProps["key"]>();

  const onAddStop = useCallback(
    (key: IStopsProps["key"]) => {
      setStops((stops) => {
        const newStops = [...stops];
        newStops[stops.findIndex((x) => x.key === key)].active = true;
        return stops;
      });

      if (key === "backgroundCheckConfig") {
        setBackgroundCheckConfig({
          enabled: true,
          disableConsent: CONFIG_DEFAULT_VALUES.disableConsent,
          skipView: CONFIG_DEFAULT_VALUES.skipView,
          ...(sdkCustomerConfig?.bcMonitoringAllowed
            ? { enableMonitoring: CONFIG_DEFAULT_VALUES.enableMonitoring }
            : {}),
        });
      } else if (key === "faceConfig") {
        setFaceConfig({
          enabled: true,
          maxAttempts: CONFIG_DEFAULT_VALUES.maxAttempts,
          ...(sdkCustomerConfig?.oneToNFaceVerificationAllowed
            ? {
                oneToNFaceVerification:
                  CONFIG_DEFAULT_VALUES.oneToNFaceVerification,
              }
            : {}),
        });
      } else {
        setScanConfigAdditionalScanConfig({
          ...(sdkCustomerConfig?.enforceDocumentExpiryCheckAllowed
            ? {
                enforceDocumentExpiryCheck:
                  CONFIG_DEFAULT_VALUES.enforceDocumentExpiryCheck,
              }
            : {}),
          ...(sdkCustomerConfig?.screenScanningAllowed
            ? {
                allowNonPhysicalDocuments:
                  CONFIG_DEFAULT_VALUES.allowNonPhysicalDocuments,
              }
            : {}),
          ...(sdkCustomerConfig?.disableTamperingRejectionAllowed
            ? {
                disableTamperingRejection:
                  CONFIG_DEFAULT_VALUES.disableTamperingRejection,
              }
            : {}),
        });
      }
    },
    [
      sdkCustomerConfig?.bcMonitoringAllowed,
      sdkCustomerConfig?.disableTamperingRejectionAllowed,
      sdkCustomerConfig?.enforceDocumentExpiryCheckAllowed,
      sdkCustomerConfig?.oneToNFaceVerificationAllowed,
      sdkCustomerConfig?.screenScanningAllowed,
      setBackgroundCheckConfig,
      setFaceConfig,
      setScanConfigAdditionalScanConfig,
    ]
  );

  const onActivateNode = useCallback(
    (node: IStopsProps["key"]) => {
      setActiveNode(node);
    },
    [setActiveNode]
  );

  const isValidWorkflow = useMemo(() => {
    return (
      documentsConfigsRequiredList.length > 0 ||
      documentsConfigsOptionalList.length > 0
    );
  }, [
    documentsConfigsRequiredList.length,
    documentsConfigsOptionalList.length,
  ]);

  const saveWorkflow = useCallback(() => {
    const config: IOnboardingWorkflow = {
      scanConfig: {
        ...(scanConfigAdditionalScanConfig ?? {}),
        required: !_.isEmpty(documentsConfigsRequiredList)
          ? {
              documentsConfigs: documentsConfigsRequiredList,
            }
          : null,
        optional: !_.isEmpty(documentsConfigsOptionalList)
          ? [
              {
                documentsConfigs: documentsConfigsOptionalList,
              },
            ]
          : null,
      },
      faceConfig,
      backgroundCheckConfig,
    };

    return saveConfigs({ onboardingWorkflow: config });
  }, [
    scanConfigAdditionalScanConfig,
    documentsConfigsRequiredList,
    documentsConfigsOptionalList,
    faceConfig,
    backgroundCheckConfig,
    saveConfigs,
  ]);

  const onboardingWorkflow = useMemo(
    () => configsDisplay?.onboardingWorkflow,
    [configsDisplay?.onboardingWorkflow]
  );

  useEffect(() => {
    if (onboardingWorkflow !== undefined) {
      setScanConfigAdditionalScanConfig(onboardingWorkflow?.scanConfig);

      init(
        onboardingWorkflow?.scanConfig?.required?.documentsConfigs,
        setIsSelectedDocumentRequired,
        setIsSelectedCountryRequired
      );

      init(
        onboardingWorkflow?.scanConfig?.optional?.[0]?.documentsConfigs,
        setIsSelectedDocumentOptional,
        setIsSelectedCountryOptional
      );

      setFaceConfig(onboardingWorkflow?.faceConfig);
      setBackgroundCheckConfig(onboardingWorkflow?.backgroundCheckConfig);

      setStops((oldStops) => {
        const updatedStops = oldStops.map((stp) => ({ ...stp, active: false }));
        const newStops = [...updatedStops];
        Object.entries(onboardingWorkflow ?? {}).forEach(([key, value]) => {
          if (value) {
            if (key !== "scanConfig") {
              const index = newStops.findIndex((x) => x.key === key);
              newStops[index].active = true;
            } else {
              Object.entries(onboardingWorkflow?.scanConfig ?? {}).forEach(
                ([scanKeys, values]) => {
                  if (values) {
                    const index = newStops.findIndex((x) => x.key === scanKeys);
                    if (workflowStatic.find((x) => x.key === scanKeys)) {
                      newStops[index].active = true;
                    }
                  }
                }
              );
            }
          }
        });
        return newStops;
      });
    }
  }, [
    onboardingWorkflow,
    setBackgroundCheckConfig,
    setFaceConfig,
    setScanConfigAdditionalScanConfig,
  ]);

  const deleteStopByKey = useCallback(
    (key: IStopsProps["key"]) => {
      setStops((stops) => {
        const newStops = [...stops];
        newStops[stops.findIndex((x) => x.key === key)].active = false;
        if (key === "required") {
          init(
            undefined,
            setIsSelectedDocumentRequired,
            setIsSelectedCountryRequired
          );
        } else if (key === "optional") {
          init(
            undefined,
            setIsSelectedDocumentOptional,
            setIsSelectedCountryOptional
          );
        } else if (key === "faceConfig") {
          setFaceConfig(undefined);
        } else if (key === "backgroundCheckConfig") {
          setBackgroundCheckConfig(undefined);
        }
        setActiveNode((activeNode) => {
          if (activeNode === key) return undefined;
          return activeNode;
        });

        return newStops;
      });
    },
    [setBackgroundCheckConfig, setFaceConfig]
  );

  useEffect(() => {
    if (!sdkCustomerConfig) fetchSdkCustomerConfig();
  }, [fetchSdkCustomerConfig, sdkCustomerConfig]);

  return {
    stops,
    setStops,
    activeNode,
    onAddStop,
    onActivateNode,
    saveWorkflow,
    isValidWorkflow,
    deleteStopByKey,
    scanConfigAdditionalScanConfig,
    setScanConfigAdditionalScanConfig,
    onChangeScanAdditionalConfigs,
    faceConfig,
    setFaceConfig,
    onChangeFaceConfig,
    backgroundCheckConfig,
    setBackgroundCheckConfig,
    onChangeBackgroundCheckConfig,
    isSelectedDocumentRequired,
    setIsSelectedDocumentRequired,
    isSelectedCountryRequired,
    setIsSelectedCountryRequired,
    isSelectedDocumentOptional,
    setIsSelectedDocumentOptional,
    isSelectedCountryOptional,
    setIsSelectedCountryOptional,
  };
}

export default useWorkflowConfigs;
