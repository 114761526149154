import { Heading4 } from "components/core/Styled/Texts";
import { Container } from "components/core/Styled/Layouts";
import ReactSelect from "react-select";
import styled, { useTheme } from "styled-components";
import { useMemo } from "react";
import { TOption } from "common/types/common";

export type IProps<T> = {
  label?: string;
  options?: TOption<T>[];
  selected?: TOption<T>;
  className?: string;
  selectStyle?: string;
  onChange?: (newValue: TOption<T>) => void;
  hideSelectedOptions?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  forceClear?: boolean;
};

const CustomSelect = <T,>({
  label,
  onChange,
  options,
  selected,
  className,
  selectStyle,
  hideSelectedOptions,
  placeholder,
  isMulti,
  forceClear,
}: IProps<T>) => {
  const theme = useTheme();

  const customStyles = useMemo(
    () => ({
      control: (provided: Record<string, unknown>) => ({
        ...provided,
        borderColor: "white",
        height: "2em",
        width: selectStyle ? selectStyle : "26.1em",
        borderRadius: theme.borderRadius.primary,
        "&:hover": {
          borderColor: "white",
        },
      }),
    }),
    [selectStyle, theme.borderRadius.primary]
  );

  const rest = forceClear ? { value: null } : {};

  return (
    <StyledContainer className={className}>
      {label && <StyledTitle>{label}</StyledTitle>}
      <Wrapper>
        <SelectStyled
          selectStyle={selectStyle}
          defaultValue={selected}
          isLoading={false}
          isSearchable
          options={options}
          onChange={(newValue: unknown) => onChange?.(newValue as TOption<T>)}
          styles={customStyles}
          hideSelectedOptions={hideSelectedOptions}
          placeholder={placeholder}
          isMulti={isMulti}
          {...rest}
        />
      </Wrapper>
    </StyledContainer>
  );
};

export default CustomSelect;

const StyledContainer = styled(Container)`
  flex-direction: column;
  width: 26.25em;
  align-items: flex-start;
`;
const Wrapper = styled(Container)`
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 0.375em;
  border: ${(props) => props.theme.border.secondary};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  min-height: 2.1875em;
  color: ${(props) => props.theme.color.primary};
`;

const SelectStyled = styled(ReactSelect)<{ selectStyle?: string }>`
  width: ${(props) => (props.selectStyle ? props.selectStyle : "26.25em")};
`;

const StyledTitle = styled(Heading4)`
  color: ${(props) => props.theme.color.secondary2};
  padding: 0.75em 0;
`;
