import FaceMatch, { FaceMatchError } from "./FaceMatch";
import Scan, { ScanError } from "./Scan";
import Lookup, { LookupError } from "./Lookup";
import BackgroundCheck, { BackgroundCheckError } from "./BackgroundCheck";
import ReadNfc, { ReadNfcError } from "./ReadNfc";
import { IAction } from "common/types/api/document";
import { IEvent } from "common/types/api/documentEvent";

type IProps = {
  info: IAction;
};

export const Document = ({ info }: IProps) => {
  if (info.type === "SCAN" && info.data) {
    return <Scan info={info.data} />;
  }

  if (info.type === "FACE" && info.data) {
    return <FaceMatch info={info.data} />;
  }

  if (info.type === "READING" && info.data) {
    return <ReadNfc info={info.data} />;
  }

  if (info.type === "BACKGROUND_CHECK" && info.data) {
    return <BackgroundCheck info={info.data} />;
  }

  if (info.type === "LOOKUP" && info.data) {
    return <Lookup info={info.data} />;
  }

  return <></>;
};

type IPropsError = {
  info: IEvent;
};

export const DocumentError = ({ info }: IPropsError) => {
  if (info.type === "SCAN" && info.data)
    return <ScanError info={info.data} infoEvent={info} />;
  if (info.type === "FACE" && info.data)
    return <FaceMatchError info={info.data} infoEvent={info} />;
  if (info.type === "READING" && info.data)
    return <ReadNfcError info={info.data} infoEvent={info} />;
  if (info.type === "BACKGROUND_CHECK" && info.data)
    return <BackgroundCheckError info={info.data} infoEvent={info} />;
  if (info.type === "LOOKUP" && info.data)
    return <LookupError info={info.data} infoEvent={info} />;

  return <></>;
};

export default Document;
