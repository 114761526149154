import { ILookupInfo } from "common/types/api/document";
import { Container, Divider, Row } from "components/core/Styled/Layouts";
import { Heading3, Heading4 } from "components/core/Styled/Texts";
import Status from "components/Status/Status";
import Image from "components/reusable/Image";
import { fromCamelCaseToString, infoToString } from "utils/helpers";
import { TEventInfo } from "common/types/api/documentEvent";
import { Fragment, useMemo } from "react";
import styled from "styled-components";

type IProps = {
  info: ILookupInfo;
};

export const FaceMatchPictures = ({ info }: IProps) => {
  const pictures = useMemo(() => {
    if (info.faceImage) {
      return {
        first: info.faceImage,
      };
    }
    return {
      first: info.faceImage,
    };
  }, [info.faceImage]);

  return (
    <PictureContainer>
      {pictures.first && (
        <Container>
          <Title>ID Document</Title>
          <FirstImageStyled info={pictures.first} />
        </Container>
      )}
    </PictureContainer>
  );
};

export const Lookup = ({ info }: IProps) => {
  if (info.status === "FAILED") {
    return (
      <Wrapper>
        <DescriptionContainer>
          <br />
          <WarningText>
            An error occurred during the lookup process.
          </WarningText>
          <br />
          <WarningText>{`Error Code: ${info.statusCode}.`}</WarningText>
        </DescriptionContainer>
        {info.faceImage && (
          <PictureContainer>
            <ImageStyled info={info.faceImage} />
          </PictureContainer>
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <DescriptionContainer>
        <FaceMatchPictures info={info} />
        {info.content?.map((section, index) => (
          <Fragment key={index}>
            <Section>
              <Title>{section.title}</Title>
              {section.attributes &&
                Object.entries(section.attributes).map(
                  ([name, value], index) => (
                    <Row
                      key={index}
                      gap="1em"
                      width="100%"
                      minHeight="2.1875em"
                      justifyContent="space-between"
                      alignContent="center"
                    >
                      <StyledSubTitle>
                        {fromCamelCaseToString(name)}
                      </StyledSubTitle>
                      <RowValue>
                        <Heading3 textAlign="left">
                          {infoToString(value)}
                        </Heading3>
                        <Status isSuccess showText={false} showIcon />
                      </RowValue>
                    </Row>
                  )
                )}
            </Section>
            <Divider />
          </Fragment>
        ))}
      </DescriptionContainer>
    </Wrapper>
  );
};

type IPropsError = {
  info: ILookupInfo;
  infoEvent: TEventInfo;
};

export const LookupError = ({ info, infoEvent }: IPropsError) => {
  return (
    <Wrapper>
      <DescriptionContainer>
        <WarningText>{infoEvent.statusCode}</WarningText>
      </DescriptionContainer>
      {info.faceImage && (
        <PictureContainer>
          <ImageStyled info={info.faceImage} />
        </PictureContainer>
      )}
    </Wrapper>
  );
};

export default Lookup;

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const PictureContainer = styled(Container)`
  width: 100%;
  align-items: flex-start;
`;

const DescriptionContainer = styled(Container)`
  width: 50%;
  justify-content: flex-start;
`;

const Section = styled(Container)`
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 1em;
`;

const RowValue = styled(Row)`
  width: 100%;
  justify-content: space-between;
  flex: 2;
  padding: 0.25em 0;
  border-bottom: ${(props) => props.theme.border.main};
`;

const Title = styled(Heading3)`
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  border-bottom: ${(props) => props.theme.border.main};
  color: ${(props) => props.theme.color.main};
  padding: 1.875em 0;
`;

const StyledSubTitle = styled(Heading4)`
  width: 100%;
  flex: 1;
  text-align: left;
  padding: 0.25em 0;
`;

const ImageStyled = styled(Image)`
  width: 35em;
  padding: 3em;
`;
const FirstImageStyled = styled(Image)`
  width: 12em;
  padding: 0.5em;
`;

const WarningText = styled(Heading3)`
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.color.danger};
`;
