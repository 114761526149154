import ListPage from "./ListPage";
import EditTextPage from "./EditTextPage";
import UpdatePage from "./UpdatePage";
import OnboardingWorkflowPage from "./OnboardingWorkflowPage";
import LoadingPage from "components/core/Loaders/LoadingPage";
import { routesList } from "utils/routesList";
import ProtectedRoutes from "routes/ProtectedRoutes";
import { Outlet, RouteObject } from "react-router-dom";
import { PropsWithChildren, Suspense } from "react";
import { ConfigNoCodeProvider } from "common/contexts/noCodeContext";
import { ConfigWorkflowProvider } from "common/contexts/workflowContext";

const ContainerConfigNoCode = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ConfigNoCodeProvider>
        {children ? children : <Outlet />}
      </ConfigNoCodeProvider>
    </Suspense>
  );
};

const ContainerConfigWorkflow = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <ConfigWorkflowProvider>
        {children ? children : <Outlet />}
      </ConfigWorkflowProvider>
    </Suspense>
  );
};

export const NoCodeIntegrationRoutes: RouteObject = {
  element: (
    <ProtectedRoutes
      allowedRoles={routesList.noCodeIntegration.allowedRoles}
      requiredCustomer={routesList.noCodeIntegration.requiredCustomer}
    />
  ),
  children: [
    {
      path: routesList.noCodeIntegration.routes.main,
      element: <ListPage />,
    },

    {
      element: <ContainerConfigNoCode />,
      children: [
        {
          path: routesList.noCodeIntegration.routes.editText,
          element: <EditTextPage />,
        },

        {
          element: <ContainerConfigWorkflow />,
          children: [
            {
              path: routesList.noCodeIntegration.routes.onboardingWorkflow,
              element: <OnboardingWorkflowPage />,
            },
          ],
        },

        {
          path: routesList.noCodeIntegration.routes.update,
          element: <UpdatePage />,
        },
      ],
    },
  ],
};
