import { IGetConfigByIdResponse } from "api/hostedKycApi/getConfigById";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useGetHostedConfigByIdQuery,
  useGetSdkCustomerConfigMutation,
  useUpdateHostedConfigMutation,
} from "services/query/hostedKyc";
import { useIdPathname } from "common/hooks/paths/useParamsPathname";
import { useGetDocumentListQuery } from "services/query/documents";
import {
  getDocumentId,
  ICountryGroupeById,
  IDocumentGroupById,
} from "common/types/api/document";

type IConfigs = IGetConfigByIdResponse;

function useNoCodeConfigs() {
  const configId = useIdPathname();
  const documentListQuery = useGetDocumentListQuery();
  const [documentListById, setDocumentListById] = useState<IDocumentGroupById>(
    {}
  );
  const [countryListById, setCountryListById] = useState<ICountryGroupeById>(
    {}
  );

  const getHostedConfigByIdQuery = useGetHostedConfigByIdQuery(configId);

  const updateConfigurationMutation = useUpdateHostedConfigMutation(configId);
  const updateConfigurationMutateAsync = useMemo(
    () => updateConfigurationMutation.mutateAsync,
    [updateConfigurationMutation.mutateAsync]
  );

  const refetchData = useMemo(
    () => getHostedConfigByIdQuery.refetch,
    [getHostedConfigByIdQuery.refetch]
  );

  const getSdkCustomerConfigMutation =
    useGetSdkCustomerConfigMutation(configId);

  const getSdkCustomerConfigMutationAsync = useMemo(
    () => getSdkCustomerConfigMutation.mutateAsync,
    [getSdkCustomerConfigMutation.mutateAsync]
  );

  const sdkCustomerConfig = useMemo(
    () => getSdkCustomerConfigMutation.data,
    [getSdkCustomerConfigMutation.data]
  );

  const fetchSdkCustomerConfig = useCallback(async () => {
    return await getSdkCustomerConfigMutationAsync({});
  }, [getSdkCustomerConfigMutationAsync]);

  const configsData = useMemo(
    () => getHostedConfigByIdQuery.data,
    [getHostedConfigByIdQuery.data]
  );

  const configsDisplay = useMemo(() => configsData, [configsData]);

  const saveConfigs = useCallback(
    async (configs: Partial<IConfigs>) => {
      if (!configsData) return;
      await updateConfigurationMutateAsync({ ...configsData, ...configs });
      await refetchData();
    },
    [configsData, refetchData, updateConfigurationMutateAsync]
  );

  useEffect(() => {
    if (documentListQuery.data) {
      const documentList: IDocumentGroupById = {};
      const countryList: ICountryGroupeById = {};
      documentListQuery.data.forEach((doc) => {
        const documentId = getDocumentId(doc.documentType, doc.country);
        documentList[documentId] = doc;
        if (!countryList[doc.country]) {
          countryList[doc.country] = {
            country: doc.country,
            documents: [],
          };
        }
        countryList?.[doc.country]?.documents.push(doc.documentType);
      });
      setDocumentListById(documentList);
      setCountryListById(countryList);
    }
  }, [documentListQuery.data]);

  return {
    refetchData,
    configsData,
    configId,
    configsDisplay,
    saveConfigs,
    sdkCustomerConfig,
    fetchSdkCustomerConfig,
    documentListById,
    countryListById,
  };
}

export default useNoCodeConfigs;
