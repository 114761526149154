import { ITextSettings } from "common/types/api/hostedKyc";
import { Container } from "components/core/Styled/Layouts";
import { SubTitle, Heading3 } from "components/core/Styled/Texts";
import { EmptyType } from "common/types/common";
import { fromCamelCaseToString, getMessageError } from "utils/helpers";
import Button from "components/reusable/Button";
import TextAreaField from "components/reusable/Textarea";
import { routesList } from "utils/routesList";
import { useGoTo } from "common/hooks/paths/useGoTo";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { HiOutlineCheck } from "react-icons/hi";
import styled from "styled-components";
import { customToast } from "utils/customToast";
import { hostedTextOrder } from "utils/EditTextsHosted";
import { useContextNoCode } from "common/contexts/noCodeContext";
import LoadingPage from "components/core/Loaders/LoadingPage";
import _ from "lodash";
import { isValidString } from "utils/assertion";
import { MIN_LENGTH_STRING } from "utils/constants";

const EditTexts = () => {
  const goTo = useGoTo();
  const { configId, configsDisplay, saveConfigs } = useContextNoCode();
  const [texts, setTexts] = useState<EmptyType<ITextSettings>>();

  const onReset = useCallback(
    (key1: string, key2: string) => {
      {
        const defaultSettingsText = configsDisplay?.defaultTextSettings;

        setTexts((oldSettingsText) => {
          const newSettingsText = { ...oldSettingsText };
          newSettingsText[key1][key2] =
            defaultSettingsText?.[key1]?.[key2] ?? "";
          return newSettingsText;
        });
      }
    },
    [configsDisplay?.defaultTextSettings]
  );

  const onChangeValue = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement>,
      key1: string,
      key2: string
    ) => {
      setTexts((oldSettingsText) => {
        const newSettingsText = { ...oldSettingsText };
        newSettingsText[key1][key2] = event.target.value;
        return newSettingsText;
      });
    },
    []
  );

  useEffect(() => {
    const res: ITextSettings = {};
    const oldTexts = configsDisplay?.textSettings ?? {};

    Object.entries(configsDisplay?.defaultTextSettings ?? {}).forEach(
      ([key1, obj]) => {
        return Object.entries(obj).forEach(([key2, value]) => {
          const newValue = oldTexts?.[key1]?.[key2] ?? value;
          if (res?.[key1] === undefined) {
            res[key1] = {};
          }
          res[key1][key2] = newValue;
        });
      }
    );

    setTexts(res);
  }, [configsDisplay?.textSettings, configsDisplay?.defaultTextSettings]);

  const onSaveTexts = useCallback(async () => {
    if (
      !texts ||
      Object.values(texts).some(
        (text) =>
          !text ||
          Object.values(text).some((val) => {
            const res = isValidString(val, { min: MIN_LENGTH_STRING });
            return !res;
          })
      )
    ) {
      customToast.error(
        `All fields must be at least ${MIN_LENGTH_STRING} characters long.`
      );
      return;
    }

    try {
      await saveConfigs({ textSettings: texts });
      customToast.success("No-code KYC texts saved successfully");
      goTo(routesList.noCodeIntegration.routes.update, configId);
    } catch (error) {
      customToast.error(
        getMessageError(error, "No-code KYC texts save failed")
      );
      console.error(error);
    }
  }, [configId, goTo, saveConfigs, texts]);

  if (_.isEmpty(texts)) return <LoadingPage />;

  return (
    <Wrapper>
      {!!texts &&
        hostedTextOrder.map((section) => {
          if (section.value === "selectDocument") {
            return (
              texts[section.value] && (
                <Fragment key={section.value}>
                  <Title>{fromCamelCaseToString(section.label)}</Title>
                  {Object.entries(texts[section.value]).map(([key, val]) => (
                    <StyledTextField
                      key={key}
                      withReset
                      defaultValue={val}
                      value={val || ""}
                      onReset={() => onReset(section.value, key)}
                      onChange={(event) =>
                        onChangeValue(event, section.value, key)
                      }
                    />
                  ))}
                </Fragment>
              )
            );
          } else {
            const reversedInputs = Object.entries(texts[section.value])
              .map(([key, val]) => (
                <StyledTextField
                  key={key}
                  withReset
                  defaultValue={val}
                  value={val || ""}
                  onReset={() => onReset(section.value, key)}
                  onChange={(event) => onChangeValue(event, section.value, key)}
                />
              ))
              .reverse();

            return (
              texts[section.value] && (
                <Fragment key={section.value}>
                  <Title>{fromCamelCaseToString(section.label)}</Title>
                  {reversedInputs}
                </Fragment>
              )
            );
          }
        })}

      <ButtonStyled onClick={onSaveTexts} iconBefore={<HiOutlineCheck />}>
        <Heading3>Save</Heading3>
      </ButtonStyled>
    </Wrapper>
  );
};

export default EditTexts;

const Wrapper = styled(Container)`
  padding: 0 1.875em;
  margin: 1.875em 0;
  align-items: flex-start;
  justify-content: flex-start;
  height: 80vh;
`;
const StyledTextField = styled(TextAreaField)`
  margin: 0 0 1em 0;
`;

const Title = styled(SubTitle)`
  color: ${(props) => props.theme.color.primary};
  font-weight: 500;
  padding: 0 0 1em 0;
`;

const ButtonStyled = styled(Button)<{ cancel?: boolean }>`
  border: ${(props) => (props.cancel ? "none" : props.theme.border.primary)};
  border-radius: ${(props) => props.theme.borderRadius.primary};
  width: 26.25em;
  color: ${(props) =>
    props.cancel ? props.theme.color.black : props.theme.color.main};
  padding: 0 0.625em;
  margin-top: 1em;
  min-height: 2.1875em;
`;
